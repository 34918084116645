import React from 'react';
import styles from './VariantButton.module.scss';

interface Props {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  imgSrc: string;
  active: boolean;
}

const VariantButton: React.FC<Props> = ({ onClick, imgSrc, active }: Props) => (
  <button
    onClick={onClick}
    className={`${styles.circular_button} ${active ? styles.circular_button_active : ''}`}
    style={{ backgroundImage: `url(${imgSrc})` }}
  />
);

export default VariantButton;

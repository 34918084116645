export const isNumeric = (input: string) => (
  // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  !Number.isNaN(parseInt(input, 10)) &&
  !Number.isNaN(parseFloat(input))
);

/**
 * CapitaliseWord
 *
 * @description Helper method for capitalising the first letter of a word
 * @param {string} text Selected word to capitalise
 * @returns {string} Capitalised word
 */
export const capitaliseWord = (text: string): string => {
  const capitalisedFirstLetter = text.charAt(0).toUpperCase();

  return capitalisedFirstLetter + text.substr(1, text.length);
};

/* eslint-disable react/prop-types */
import React from 'react';

import styles from './Button.module.scss';

interface Props {
  className?: string;
  icon?: React.ReactNode;
  centered?: boolean;
  onClick?: () => void;
}

const Button: React.FC<Props> = ({
  centered,
  onClick,
  children,
  className = ''
}) => (
  <button
    type='button'
    className={`${styles.noStyleButton} ${centered ? styles.centered : ''} ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;

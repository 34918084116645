import { Header } from 'components';
import React, { useEffect } from 'react';

import styles from './BasicPageLayout.module.scss';

interface IBasicPageLayout {
  title: string;
  sidebarContent?: React.ReactNode;
  footnote?: string;
  className?: string;
}

const BasicPageLayout: React.FC<IBasicPageLayout> = ({
  title,
  sidebarContent,
  footnote,
  className = '',
  children
}) => {
  useEffect(() => {
    document.body.classList.add('fixed__layout');

    return (() => {
      document.body.classList.remove('fixed__layout');
    });
  });

  return (
    <>
      <Header />
      <div className={styles.basicPage__fixedContainer}>
        <div className={styles.sidebar}>
          <h1 className={styles.sidebar__title}>{title}</h1>
          {
            sidebarContent
          }
          {
            footnote &&
            <p className={styles.sidebar__footnote}>
              {footnote}
            </p>
          }
        </div>
      </div>

      <div className={`${styles.scrollingContainer} ${className}`}>
        <div className={styles.scrollingContainer__inner}>
          {children}
        </div>
      </div>
    </>
  );
};

export default BasicPageLayout;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
interface History {
  push: (path: string, state?: unknown) => void;
}

export default {
  pages: {
    home: {
      path: '/',
      push: (history: History) => history.push('/')
    },
    products: {
      path: '/products',
      push: (history: History) => history.push('/products')
    },
    product: {
      path: '/products/:productId',
      push: (history: History, productId: string) => history.push(`/products/${productId}`)
    },
    cart: {
      path: '/cart',
      push: (history: History) => history.push('/cart')
    },
    privacyPolicy: {
      path: '/privacy-policy',
      push: (history: History) => history.push('/privacy-policy')
    },
    guides: {
      root: {
        path: '/guides',
        push: (history: History) => history.push('/guides')
      },
      rollerBlinds: {
        path: '/guides/roller-blinds',
        push: (history: History) => history.push('/products/roller-blinds')
      },
      doubleRollerBlinds: {
        path: '/guides/double-roller-blinds',
        push: (history: History) => history.push('/products/double-roller-blinds')
      },
      verticalBlinds: {
        path: '/guides/vertical-blinds',
        push: (history: History) => history.push('/products/vertical-blinds')
      },
      plantation: {
        path: '/guides/plantation-blinds',
        push: (history: History) => history.push('/products/plantation-blinds')
      }
    }
  }
};

import React from 'react';

import { productSliderHeight } from 'common/constants';
import { Product } from 'types/productTypes';

import { AdditionalSelectionContainer, RadioButton } from 'components';

import styles from './ChainSelection.module.scss';

interface IChainSelection {
  chainProduct: Product | null | undefined;
  selectedChainId: string | null | undefined;
  setSelectedChain: React.Dispatch<string | null | undefined>;
  setControlSide: React.Dispatch<string | null | undefined>;
}

const ChainSelection: React.FC<IChainSelection> = ({
  chainProduct, selectedChainId, setSelectedChain, setControlSide
}) => {
  /**
   * HandleRadioOnChange
   *
   * @description Handle radio button change event
   * @param {string} value Radio input value
   * @param {boolean} checked Whether the radio button is checked
   */
  const handleRadioOnChange = (value: string, checked: boolean) => {
    if (value === 'left_side' && checked) {
      setControlSide('Left side');
    }

    if (value === 'right_side' && checked) {
      setControlSide('Right side');
    }
  };

  if (!chainProduct) {
    return null;
  }

  return (
    <AdditionalSelectionContainer
      className={styles.form_selection__container}
      height={productSliderHeight}
      title='Its the details that count'
      additionalProductHeading='Select chain material'
      variantProduct={chainProduct}
      selectedVariantId={selectedChainId}
      setSelectedVariant={setSelectedChain}>

      <h4 className={styles.form_selection__sub_heading}>Control side</h4>
      <div>
        <RadioButton
          name='controlSide'
          label='Left side'
          value='left_side'
          onChange={handleRadioOnChange}
        />
        <RadioButton
          name='controlSide'
          label='Right side'
          value='right_side'
          onChange={handleRadioOnChange}
        />
      </div>
    </AdditionalSelectionContainer>
  );
};

export default ChainSelection;

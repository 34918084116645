import { variantImageMap } from 'common/constants';
import { Product } from 'types/productTypes';

/**
 * GetRelatedProducts
 *
 * @description Given an array products and a product, return an array of related products
 * @param {Array<Product>} products
 * @param {Product} product
 * @returns {Array<Product>} Array of related products related to the product param
 */
export const getRelatedProducts = (products: Array<Product>, product: Product): Array<Product> => {
  const similarIds = new Set([product.id]);
  let similarProducts: Array<Product> = [];

  const typedProducts = products.filter(({ type }) => type === product.type);
  if (typedProducts.length <= 3) {
    similarProducts = typedProducts.filter(({ id }) => id !== product.id);
  }
  else {
    while (similarProducts.length < 3) {
      const product = typedProducts[Math.floor(Math.random() * typedProducts.length)];

      if (!similarIds.has(product.id)) {
        similarIds.add(product.id);
        similarProducts.push(product);
      }
    }
  }

  return similarProducts;
};

/**
 * GetVariantImage
 *
 * @description Get the relevant variant button image for based on the manual mapping we maintain
 * @param {string} variantName Selected variant name
 * @returns {string | null} Variant image url
 */
export const getVariantImg = (variantName: string): string | null => {
  if (variantName in variantImageMap) {
    return variantImageMap[variantName];
  }

  return null;
};

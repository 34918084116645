import React from 'react';

import { Product } from 'types/productTypes';
import { productSliderHeight } from 'common/constants';

import { AdditionalSelectionContainer, RadioButton } from 'components';

import styles from './RollTypeSelection.module.scss';

interface IRollTypeSelection {
  railProduct: Product | null | undefined;
  selectedBottomRailId: string | null | undefined;
  setSelectedChain: React.Dispatch<string | null | undefined>;
  setRollType: React.Dispatch<string | null | undefined>;
}

const RollTypeSelection: React.FC<IRollTypeSelection> = ({
  railProduct, selectedBottomRailId, setSelectedChain, setRollType
}) => {
  /**
   * HandleRadioOnChange
   *
   * @description Handle radio button change event
   * @param {string} value Radio input value
   * @param {boolean} checked Whether the radio button is checked
   */
  const handleRadioOnChange = (value: string, checked: boolean) => {
    if (value === 'over_roll' && checked) {
      setRollType('Over roll');
    }

    if (value === 'under_roll' && checked) {
      setRollType('Under roll');
    }
  };

  if (!railProduct) {
    return null;
  }

  return (
    <AdditionalSelectionContainer
      className={styles.form_selection__container}
      height={productSliderHeight}
      title='Make it your own'
      additionalProductHeading='Select bottom rail colour'
      variantProduct={railProduct}
      selectedVariantId={selectedBottomRailId}
      setSelectedVariant={setSelectedChain}>

      <h4 className={styles.form_selection__sub_heading}>Roll type</h4>
      <div>
        <RadioButton
          name='rollType'
          label='Over roll'
          value='over_roll'
          onChange={handleRadioOnChange}
        />
        <RadioButton
          name='rollType'
          label='Under roll'
          value='under_roll'
          onChange={handleRadioOnChange}
        />
      </div>
    </AdditionalSelectionContainer>
  );
};

export default RollTypeSelection;

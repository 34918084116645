import React, { useContext, useState } from 'react';
import { Popconfirm } from 'antd';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { LineItem } from 'types/productTypes';

import { ShopContext } from 'context/ShopContext';

import Button from 'components/Button';

import styles from './CartItemDetailed.module.scss';

const CartItemDetailed: React.FC<LineItem> = ({
  id, title, variantTitle, price, image, customAttributes
}) => {
  const { Height, Width, Area } = customAttributes;

  const { removeItemFromCheckout, error } = useContext(ShopContext);
  const [confirmLoading, setConfirmLoading] = useState<boolean>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const removeCartItem = async () => {
    setConfirmLoading(true);
    await removeItemFromCheckout(id);

    setConfirmLoading(false);
    setShowConfirm(false);
    // Todo display error to user
    if (error) {
      alert(error);
    }
  };

  return (
    <div className={styles.cartItem__container}>
      <div className={styles.cartItem__imageContainer}>
        <img src={image.url} alt={image.alt} />
      </div>

      <div className={styles.cartItem__contentContainer}>
        <Popconfirm
          title='Are you sure?'
          onConfirm={removeCartItem}
          placement='top'
        >
          <Button className={styles.cartItem__removeButton}>
            <IoCloseCircleOutline size={24} />
          </Button>
        </Popconfirm>
        <div>
          <h1>{title}</h1>
          {
            variantTitle &&
            <p className={styles.cartItem__variant}>{variantTitle}</p>
          }
        </div>

        <div className={styles.cartItem__priceContainer}>
          <p>{Height} x {Width}</p>
          <p className={styles.cartItem__price}>Price: ${parseInt(price, 10) * parseInt(Area, 10)}</p>
        </div>
      </div>
    </div>
  );
};

export default CartItemDetailed;

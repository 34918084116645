/* eslint-disable react/prop-types */
import React, { ReactNode } from 'react';

interface Props {
  className?: string;
  children: ReactNode;
}

const Section: React.FC<Props> = ({ className, children }) => (
  <section className={className ?? ''}>
    {children}
  </section>
);

export default Section;

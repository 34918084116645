import React from 'react';

import './RadioButton.scss';

interface IRadioButton {
  name: string;
  label: string;
  value: string | number;
  className?: string;
  onChange: (value: string, checked: boolean) => void;
}

/**
 * The control input component
 * Used for both checkboxes and radio buttons
 *
 */
const RadioButton: React.FC<IRadioButton> = ({
  name, value, label, onChange, className = '', ...attributeOptions
}) => {

  return (
    <>
      <div className={
        `au-control-input ${className}`
      }
      >
        <input
          className='au-control-input__input'
          name={name}
          id={value ? value?.toString() : name}
          type='radio'
          value={value}
          onChange={e => onChange(e.currentTarget.value, e.currentTarget.checked)}
          {...attributeOptions}
        />
        <label className='au-control-input__text' htmlFor={value ? value?.toString() : name}>
          {label}
        </label>
      </div>
    </>
  );
};

export default RadioButton;

import React from 'react';

import {
  Footer,
  BasicPageLayout
} from 'components';

const PrivacyPolicyPage: React.FC = () => (
  <>
    <BasicPageLayout
      title='Privacy policy'
      footnote='Our privacy policy outlines both what data we capture and the reason for capturing the selected data'
    >
      <h2>Privacy Policy</h2>
      <p>Your privacy is important to us. It is whtdocs policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, <a href='https://whtdoc.com.au/'>https://whtdoc.com.au/</a>, and other sites we own and operate. </p><p>Personal information is any information about you which can be used to identify you. This includes information about you as a person (such as name, address, and date of birth), your devices, payment details, and even information about how you use a website or online service. </p>
      <p>In the event our site contains links to third-party sites and services, please be aware that those sites and services have their own privacy policies. After following a link to any third-party content, you should read their posted privacy policy information about how they collect and use personal information. This Privacy Policy does not apply to any of your activities after you leave our site. </p>
      <p>This policy is effective as of 3 August 2021. </p>
      <p>Last updated: 3 August 2021 </p>
      <h3>Information We Collect</h3>
      <p>Information we collect falls into one of two categories: “voluntarily provided” information and “automatically collected” information. </p>
      <p>“Voluntarily provided” information refers to any information you knowingly and actively provide us when using or participating in any of our services and promotions. </p>
      <p>“Automatically collected” information refers to any information automatically sent by your devices in the course of accessing our products and services. </p>
      <h4>Log Data</h4>
      <p>When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your device’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details about your visit. </p>
      <p>Additionally, if you encounter certain errors while using the site, we may automatically collect data about the error and the circumstances surrounding its occurrence. This data may include technical details about your device, what you were trying to do when the error happened, and other technical information relating to the problem. You may or may not receive notice of such errors, even in the moment they occur, that they have occurred, or what the nature of the error is. </p><p>Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons. </p><h4>Personal Information</h4><p>We may ask for personal information — for example, when you register an account or when you contact us — which may include one or more of the following: </p>
      <ul>
        <li>Name</li>
        <li>Email</li>
      </ul>
      <h4>User-Generated Content</h4>
      <p>We consider “user-generated content” to be materials (text, image and/or video content) voluntarily supplied to us by our users for the purpose of publication, processing, or usage on our platform. All user-generated content is associated with the account or email address used to submit the materials. </p>
      <p>Please be aware that any content you submit for the purpose of publication will be public after posting (and subsequent review or vetting process). Once published, it may be accessible to third parties not covered under this privacy policy. </p>
      <h4>Transaction Data</h4>
      <p>Transaction data refers to data that accumulates over the normal course of operation on our platform. This may include transaction records, stored files, user profiles, analytics data and other metrics, as well as other types of information, created or generated, as users interact with our services. </p>
      <h4>Legitimate Reasons for Processing Your Personal Information</h4>
      <p>We only collect and use your personal information when we have a legitimate reason for doing so. In which instance, we only collect personal information that is reasonably necessary to provide our services to you. </p>
      <h4>Collection and Use of Information</h4>
      <p>We may collect personal information from you when you do any of the following on our website: </p>
      <ul>
        <li>Register for an account</li>
        <li>Purchase a subscription</li>
        <li>Use a mobile device or web browser to access our content</li>
        <li>Contact us via email, social media, or on any similar technologies</li>
        <li>When you mention us on social media</li>
      </ul>
      <p>We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a manner that is incompatible with these purposes: </p>
      <ul>
        <li>to provide you with our platforms core features and services</li>
        <li>to enable you to customize or personalize your experience of our website</li>
        <li>to deliver products and/or services to you</li>
        <li>to contact and communicate with you</li>
        <li>for advertising and marketing, including to send you promotional information about our products and services and information about third parties that we consider may be of interest to you</li>
        <li>to enable you to access and use our website, associated applications, and associated social media platforms</li>
      </ul>
      <p>We may combine voluntarily provided and automatically collected personal information with general information or research data we receive from other trusted sources. For example, If you provide us with your location, we may combine this with general information about currency and language to provide you with an enhanced experience of our site and service. </p>
      <h4>Security of Your Personal Information</h4>
      <p>When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification. </p>
      <p>Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or storage is 100% secure, and no one can guarantee absolute data security. </p>
      <p>You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services. For example, ensuring any passwords associated with accessing your personal information and accounts are secure and confidential. </p>
      <h4>How Long We Keep Your Personal Information</h4>
      <p>We keep your personal information only for as long as we need to. This time period may depend on what we are using your information for, in accordance with this privacy policy. For example, if you have provided us with personal information as part of creating an account with us, we may retain this information for the duration your account exists on our system. If your personal information is no longer required for this purpose, we will delete it or make it anonymous by removing all details that identify you. </p>
      <p>However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes. </p>
      <h3>Children’s Privacy</h3>
      <p>We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13. </p>
      <h3>Disclosure of Personal Information to Third Parties</h3>
      <p>We may disclose personal information to: </p>
      <ul>
        <li>a parent, subsidiary, or affiliate of our company</li>
        <li>third-party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, hosting and server providers, analytics, error loggers, debt collectors, maintenance or problem-solving providers, marketing providers, professional advisors, and payment systems operators</li>
        <li>our employees, contractors, and/or related entities</li>
        <li>our existing or potential agents or business partners</li>
        <li>credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to pay for goods or services we have provided to you</li>
        <li>courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise, or defend our legal rights</li>
        <li>third parties, including agents or sub-contractors, who assist us in providing information, products, services, or direct marketing to you</li>
        <li>third parties to collect and process data</li><li>an entity that buys, or to which we transfer all or substantially all of our assets and business</li>
      </ul>
      <p>Third parties we currently use include: </p>
      <ul>
        <li>Google Analytics</li>
        <li>SendGrid</li>
      </ul>
      <h3>International Transfers of Personal Information</h3>
      <p>When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification. </p>
      <p>Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or storage is 100% secure, and no one can guarantee absolute data security. </p>
      <p>You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services. For example, ensuring any passwords associated with accessing your personal information and accounts are secure and confidential. </p>
    </BasicPageLayout>
    <Footer />
  </>
);

export default PrivacyPolicyPage;

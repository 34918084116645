import React, { useEffect, useContext, useState } from 'react';

import ProductGridItem from 'components/ProductGridItem';
import ProductGrid from 'components/ProductGrid';
import Footer from 'components/Footer';

import { ShopContext } from 'context/ShopContext';
import { Product } from 'types/productTypes';

import { PRODUCT_TYPES } from 'common/constants';
import { BasicPageLayout } from 'components';

import styles from './GuidesPage.module.scss';

const GuidesPage: React.FC = () => {
  const { fetchAllProducts, products } = useContext(ShopContext);

  const [activeProductType, setActiveProductType] = useState(PRODUCT_TYPES.BLIND);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  const generateProductsGrid = (_products: Array<Product>) => (
    _products
      .filter(({ type }) => type === activeProductType)
      .map((product) => (
        <ProductGridItem
          key={product.id}
          product={product}
          guide
          xs={24}
          sm={12}
          md={8}
          xxl={6}
        />
      ))
  );

  return (
    <>
      <BasicPageLayout
        title='Guides'
        footnote='Our guides aim to make your purchase and installation as simple as possible'
        sidebarContent={
          <div className={styles.shopFilter}>
            <div className={styles.shopFilter__buttonContainer}>
              <button
                className={activeProductType === PRODUCT_TYPES.BLIND ? styles.active : ''}
                onClick={() => setActiveProductType(PRODUCT_TYPES.BLIND)}
                type='button'
              >
                Blinds
              </button>
              <button
                className={activeProductType === PRODUCT_TYPES.CURTAIN ? styles.active : ''}
                onClick={() => setActiveProductType(PRODUCT_TYPES.CURTAIN)}
                type='button'
              >
                Curtains
              </button>
            </div>
          </div>
        }
      >
        <ProductGrid gutter={0}>
          {
            products && products.length > 0 &&
              generateProductsGrid(products)
          }
        </ProductGrid>
      </BasicPageLayout>
      <Footer />
    </>
  );
};

export default GuidesPage;

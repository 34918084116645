import { Input } from 'antd';
import React from 'react';

import styles from './DimensionsInput.module.scss';

interface Props {
  height: string;
  width: string;
  setHeight: (h: string) => void;
  setWidth: (w: string) => void;
}

const DimensionsInput: React.FC<Props> = ({
  height, width, setHeight, setWidth
}) => (
  <div className={styles.dimensionsContainer}>
    <div className={styles.dimensionsContainer__inputContainer}>
      <Input
        onChange={e => setWidth(e.target.value)}
        placeholder={'width'}
        value={width}
        type='number'
      />
      <span className={styles.dimensionTag}>mm</span>
    </div>
    <p className={styles.dimensionsContainer__x}>X</p>
    <div className={styles.dimensionsContainer__inputContainer}>
      <Input
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHeight(e.target.value)}
        placeholder={'height'}
        value={height}
        type='number'
      />
      <span className={styles.dimensionTag}>mm</span>
    </div>
  </div>
);

export default DimensionsInput;

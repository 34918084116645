/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { animated, useTrail } from 'react-spring';

import homePageMock from 'assets/_temporary/home-page-step-mock@2x.png';

import Button from 'components/Button';
import styles from './StepSlider.module.scss';

const steps = ['1', '2', '3'];
const config = { mass: 5, tension: 2000, friction: 200 };

const StepSlider: React.FC = () => {
  const [stepCounter, setStepCounter] = useState<number>(0);

  // Const [animatedStyles, api] = useSpring(() => ({
  //   From: { y: 0 }
  // }));

  const trail = useTrail(steps.length, {
    config,
    y: -100,
    from: { y: 0, opacity: 0 }
  });

  // Const animateProgressBar = (count: number) => {
  //   If (count === 0) {
  //     Api.start({
  //       Y: 0
  //     });
  //   } else {
  //     Api.start({
  //       Y: (count - 1) * -90
  //     });
  //   }

  //   If (count >= 2) {
  //     SetStepCounter(0);
  //   } else {
  //     SetStepCounter(count + 1);
  //   }
  // };

  const updateStep = (count: number) => {
    if (count >= 2) {
      setStepCounter(0);
      return;
    }

    setStepCounter(count + 1);
  };

  const generateSteps = () => trail.map(({ y, ...rest }, index) => (
    <animated.div
      key={steps[index]}
      className={styles.product__details_animated}
      style={{
        ...rest,
        top: index * 100,
        // eslint-disable-next-line no-shadow
        transform: y.interpolate((y) => `translate3d(0, ${y * index + 50}px, 0)`),
        transition: 'opacity 1s',
        opacity: index === stepCounter ? 1 : 0
      }}
    >
      <h3>{`Step ${index + 1}.`}</h3>
      <h1>Select your design</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
        sed diam nonummy nibh euismod tincidunt.
      </p>
    </animated.div>
  ));

  return (
    <Row className={styles.product__container}>
      <Col className={styles.product__image__container}>
        <img src={homePageMock} alt='Test product' />
      </Col>
      <Col className={styles.product__details_container}>
        <div className={styles.product__details_overflow_container}>
          {generateSteps()}
        </div>
        <div className={styles.stepScrollBar}>
          <div className={styles.scrollPointer} />
        </div>
        <Button onClick={() => updateStep(stepCounter)}>Test</Button>
      </Col>
    </Row>
  );
};

export default StepSlider;

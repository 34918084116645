import React from 'react';

import {
  Footer,
  BasicPageLayout,
  GuideVideoEmbed
} from 'components';

const RollerBlindsGuide: React.FC = () => (
  <>
    <BasicPageLayout
      title='Installing roller blinds'
      footnote='Installing roller blinds has never been easier with these simple steps'
    >
      <div style={{ maxWidth: '800px' }}>
        <h2>Installation step 1</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla</p>
        <GuideVideoEmbed embedId='fA7nQvcpnJA' />
        <h2>Installation step 2</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla</p>
        <GuideVideoEmbed embedId='aURqwjI_GAE' />
        <h2>Installation step 3</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla</p>
        <GuideVideoEmbed embedId='r-uEfjXgdys' />
      </div>
    </BasicPageLayout>
    <Footer />
  </>
);

export default RollerBlindsGuide;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'antd';
import { Cart, LineItem } from 'shopify-buy';

import routes from 'routes';
import { marshalLineItem } from 'common/shopifyUtil';

import CartItemDetailed from 'components/CartItemDetailed';
import {
  Container, Footer, Header
} from 'components';
import { ShopContext } from 'context/ShopContext';

import styles from './CartPage.module.scss';
import TextArea from 'antd/lib/input/TextArea';

const EmptyCartBanner: React.FC = () => (
  <>
    <h1>No items in your cart..</h1>
    <Link to={routes.pages.products.path}>
      <Button type='text' className={styles.back_button}>Back to shop</Button>
    </Link>
  </>
);

const CartContent: React.FC<{ lineItems: LineItem[]; checkout: Cart; updateCheckoutNote: (note: string) => void; }>
= ({ lineItems, checkout, updateCheckoutNote }) => (
  <>
    <div className={styles.cartItems}>
      {
        lineItems
          .map(_lineItem => {
            const lineItem = marshalLineItem(_lineItem);

            return (
              <CartItemDetailed
                key={lineItem.id}
                id={lineItem.id}
                title={lineItem.title}
                variantTitle={lineItem.variantTitle}
                price={lineItem.price}
                image={lineItem.image}
                customAttributes={lineItem.customAttributes}
              />
            );
          })
      }
    </div>

    <div className={styles.cartPage__notesContainer}>
      <p><strong>Additional notes (optional)</strong></p>
      <TextArea
        placeholder='Anything we should know about your order'
        rows={4}
        autoSize={{ minRows: 3, maxRows: 3 }}
        onBlur={(e) => updateCheckoutNote(e.target.value)}
      />
    </div>

    <Button onClick={() => window.location.replace((checkout as any).webUrl ?? '')}>Checkout</Button>
  </>
);

const CartPage: React.FC = () => {
  const { checkout, updateCheckoutNote } = useContext(ShopContext);
  return (
    <>
      <Header />

      <Container className={styles.cart_container} width={900}>
        <h1 className={styles.title}>Cart</h1>
        {
          !checkout || checkout.lineItems.length < 0 ?
            <EmptyCartBanner />
            :
            <CartContent lineItems={checkout.lineItems} checkout={checkout} updateCheckoutNote={updateCheckoutNote} />
        }
      </Container>

      <Footer />
    </>
  );
};

export default CartPage;

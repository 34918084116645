import React from 'react';
import { Product } from 'types/productTypes';

import styles from './AdditionalSelectionContainer.module.scss';

interface IAdditionalSelectionContainer {
  className?: string;
  height: number;
  title: string;
  additionalProductHeading: string;
  variantProduct: Product;
  selectedVariantId: string | null | undefined;
  setSelectedVariant: React.Dispatch<string | null | undefined>;
}

const AdditionalSelectionContainer: React.FC<IAdditionalSelectionContainer> = ({
  className, height, title, additionalProductHeading, variantProduct, selectedVariantId, setSelectedVariant, children
}) => {
  /**
   * GenerateChainButtons
   *
   * @param {Product} _additionalProduct List of all products
   * @description Create the chain buttons
   */
  const generateVariantButtons = (_additionalProduct: Product) => _additionalProduct.variants.map(variant => (
    <button
      key={variant.id}
      className={styles.additionalSelection__button}
      onClick={() => setSelectedVariant(variant.id)}>
      <div
        className={
          `${styles.additionalSelection__buttonBackground}` +
          ` ${selectedVariantId === variant.id ? styles.variantSelected : ''}`
        }
        style={{ backgroundImage: `url(${variant.image.url})` }} />
      <p>{variant.title}</p>
    </button>
  ));

  return (
    <div className={`${styles.selection_container} ${className ?? ''}`} style={{ height }}>
      <div className={styles.selection_inner}>
        <h2 className={styles.selection__header}>{title}</h2>
        <h4 className={styles.selection__sub_heading}>{additionalProductHeading}</h4>
        <div className={styles.selection__button_container}>
          {
            generateVariantButtons(variantProduct)
          }
        </div>
      </div>
      {
        children
      }
    </div>
  );
};

export default AdditionalSelectionContainer;

import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import routes from 'routes';

import {
  ShopPage,
  ProductPage,
  HomePage,
  CartPage,
  PrivacyPolicyPage,
  GuidesPage
} from 'pages';
import {
  DoubleRollerBlindsGuide,
  PlantationBlindsGuide,
  RollerBlindsGuide,
  VerticalBlindsGuide
} from 'pages/guides';

import CartDrawer from 'components/CartDrawer';

import './App.scss';

const App: React.FC = () => (
  <Router>
    <CartDrawer />
    <Switch>
      <Route
        path={routes.pages.privacyPolicy.path}
      >
        <PrivacyPolicyPage />
      </Route>

      <Route
        exact
        path={routes.pages.guides.rollerBlinds.path}
      >
        <RollerBlindsGuide />
      </Route>

      <Route
        exact
        path={routes.pages.guides.doubleRollerBlinds.path}
      >
        <DoubleRollerBlindsGuide />
      </Route>

      <Route
        exact
        path={routes.pages.guides.verticalBlinds.path}
      >
        <VerticalBlindsGuide />
      </Route>

      <Route
        exact
        path={routes.pages.guides.plantation.path}
      >
        <PlantationBlindsGuide />
      </Route>

      <Route
        exact
        path={routes.pages.guides.root.path}
      >
        <GuidesPage />
      </Route>

      <Route
        path={routes.pages.product.path}
      >
        <ProductPage />
      </Route>

      <Route
        exact
        path={routes.pages.products.path}
      >
        <ShopPage />
      </Route>

      <Route
        exact
        path={routes.pages.home.path}
      >
        <HomePage />
      </Route>

      <Route
        exact
        path={routes.pages.cart.path}
      >
        <CartPage />
      </Route>
    </Switch>
  </Router>
);

export default App;

import {
  Product as _Product,
  ProductVariant as _ProductVariant,
  Image as _Image,
  LineItem as _LineItem
} from 'shopify-buy';

import {
  Product, ProductVariant, ProductImage, LineItem
} from 'types/productTypes';

export const EMPTY_IMAGE_FORMAT = {
  src: '',
  alt: 'No Image'
};

/*-------------------
 *
 *  Helper functions
 *
 *------------------ */
export const marshalProductImage = (image: _Image): ProductImage => (
  {
    url: image.src,
    alt: image.attrs
  }
);

export const marshalProductVariant = (variant: _ProductVariant): ProductVariant => (
  {
    id: variant.id.toString(),
    title: variant.title,
    image: marshalProductImage(variant.image ?? EMPTY_IMAGE_FORMAT),
    price: variant.price
  }
);

export const marshalProductVariants = (variants: _ProductVariant[]): ProductVariant[] => {
  if (!variants) {
    return [];
  }

  return variants.map(variant => marshalProductVariant(variant));
};

export const marshalProduct = (_product: _Product): Product => (
  {
    id: _product.id.toString(),
    title: _product.title,
    description: _product.description,
    type: (_product as any).productType,
    variants: marshalProductVariants(_product.variants),
    price: _product.variants[0].price,
    image: marshalProductImage(_product.variants[0].image ?? EMPTY_IMAGE_FORMAT)
  }
);

export const marshalProducts = (_products: _Product[]): Product[] => {
  if (!_products) {
    return [];
  }

  return _products.map(_product => (marshalProduct(_product)));
};

// Will delete if you are happy with the below function
// Export const marshalCustomAttribute = (_customAttribute: Object) => {
//   Const customAttribute = _customAttribute as any;
//   Return {
//     [customAttribute.key]: customAttribute.value
//   };
// };

export const marshalCustomAttributes = (_customAttributes: Array<any>) => {
  if (_customAttributes.length < 1) {
    return {};
  }

  const customAttributes: any = {};
  _customAttributes.forEach(_customAttribute => {
    const customAttribute = _customAttribute as any;
    customAttributes[customAttribute.key] = customAttribute.value;
  });

  return customAttributes;
};

export const marshalLineItem = (_lineItem: _LineItem): LineItem => {
  const correctLineItem = _lineItem as any;
  const id = correctLineItem.id;
  const title = correctLineItem.title;
  const variantTitle = correctLineItem.variant.title;
  const image = marshalProductImage(correctLineItem.variant.image);
  const price = correctLineItem.variant.price;
  const customAttributes = marshalCustomAttributes(correctLineItem.customAttributes);

  return {
    id,
    title,
    ...(variantTitle !== 'Default Title' && { variantTitle }),
    image,
    price,
    customAttributes
  };
};

export const getCustomAttribute = (customAtrributes: Array<any>, key: string) => customAtrributes.find(attributeObject => (attributeObject as any).key === key);

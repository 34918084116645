import React from 'react';
import { Link } from 'react-router-dom';

import Logo from 'components/Logo';
import Navigation from 'components/Navigation';

import styles from './Footer.module.scss';
import routes from 'routes';

const Footer: React.FC = () => (
  <footer>
    <div className={styles.addressContainer}>
      <p className={styles.address}>
        Suite 2/16 Colbeck,
        <br />
        {' '}
        Mawson Canberra ACT 2604
      </p>
      <p>
        +02 6245 1234
        <br />
        hello@blinds.com.aua
      </p>
    </div>
    <div className={styles.footer__container}>
      <Logo />

      <div className={styles.additionalPageLinks}>
        <Link to='/terms-and-conditions'>Terms and conditions</Link>
        <Link to={routes.pages.privacyPolicy.path}>Privacy policy</Link>
      </div>

      <Navigation hideCartButton />
    </div>
  </footer>
);

export default Footer;

// Roller
import almondTexture from 'assets/productVariants/roller/almond.jpeg';
import aspenTexture from 'assets/productVariants/roller/aspen.jpeg';
import blancoTexture from 'assets/productVariants/roller/blanco.jpeg';
import breezeTexture from 'assets/productVariants/roller/breeze.jpeg';
import denimTexture from 'assets/productVariants/roller/denim.jpeg';
import doveTexture from 'assets/productVariants/roller/dove.jpeg';
import figTexture from 'assets/productVariants/roller/fig.jpeg';
import fleeceTexture from 'assets/productVariants/roller/fleece.jpeg';
import fossilTexture from 'assets/productVariants/roller/fossil.jpeg';
import gullTexture from 'assets/productVariants/roller/gull.jpeg';
import lyricTexture from 'assets/productVariants/roller/lyric.jpeg';
import porpoiseTexture from 'assets/productVariants/roller/porpoise.jpeg';
import quartzTexture from 'assets/productVariants/roller/quartz.jpeg';
import quillTexture from 'assets/productVariants/roller/quill.jpeg';
import raffiaTexture from 'assets/productVariants/roller/raffia.jpeg';
import sobaTexture from 'assets/productVariants/roller/soba.jpeg';
import urbanTexture from 'assets/productVariants/roller/urban.jpeg';
import vibeTexture from 'assets/productVariants/roller/vibe.jpeg';

// Vertical
import cliffTexture from 'assets/productVariants/vertical/cliff.jpeg';
import dewTexture from 'assets/productVariants/vertical/dew.jpeg';
import frostTexture from 'assets/productVariants/vertical/frost.jpeg';
import gladeTexture from 'assets/productVariants/vertical/glade.jpeg';
import hazeTexture from 'assets/productVariants/vertical/haze.jpeg';
import magmaTexture from 'assets/productVariants/vertical/magma.jpeg';
import mooscapeTexture from 'assets/productVariants/vertical/moonscape.jpeg';
import oceanTexture from 'assets/productVariants/vertical/ocean.jpeg';
import petrolTexture from 'assets/productVariants/vertical/petrol.jpeg';
import rockTexture from 'assets/productVariants/vertical/rock.jpeg';
import sandstoneTexture from 'assets/productVariants/vertical/sandstone.jpeg';
import shellTexture from 'assets/productVariants/vertical/shell.jpeg';

export const NO_STORE_FRONT_API_KEY = 'NO_STORE_FRONT_API_KEY';
export const NO_SHOPIFY_DOMAIN = 'NO_SHOPIFY_DOMAIN';

export const PRODUCT_TYPES = Object.freeze({
  CURTAIN: 'curtain',
  BLIND: 'blind',
  CHAIN: 'chain',
  BOTTOM_RAIL: 'bottom_rail'
});

export const variantImageMap: Record<string, string> = {
  almond: almondTexture,
  aspen: aspenTexture,
  blanco: blancoTexture,
  breeze: breezeTexture,
  denim: denimTexture,
  dove: doveTexture,
  fig: figTexture,
  fleece: fleeceTexture,
  fossil: fossilTexture,
  gull: gullTexture,
  lyric: lyricTexture,
  porpoise: porpoiseTexture,
  quartz: quartzTexture,
  quill: quillTexture,
  raffia: raffiaTexture,
  soba: sobaTexture,
  urban: urbanTexture,
  vibe: vibeTexture,

  cliff: cliffTexture,
  dew: dewTexture,
  frost: frostTexture,
  glade: gladeTexture,
  haze: hazeTexture,
  magma: magmaTexture,
  moonscape: mooscapeTexture,
  ocean: oceanTexture,
  petrol: petrolTexture,
  rock: rockTexture,
  sandstone: sandstoneTexture,
  shell: shellTexture
};

export const productSliderHeight = 500;

import React, { useContext, useState } from 'react';
import { Modal } from 'antd';
import { animated, useSpring } from 'react-spring';

import { ShopContext } from 'context/ShopContext';
import { IAddToCartProductDetails } from 'context/shopContextTypes';
import { capitaliseWord, isNumeric } from 'common/util';
import { Product } from 'types/productTypes';
import { productSliderHeight, PRODUCT_TYPES } from 'common/constants';

import {
  Button,
  ChainSelection,
  DimensionsInput,
  RollTypeSelection,
  VariantButton
} from 'components';
import { getVariantImg } from 'pages/ProductPage/ProductUtils'; // TODO: Move this

import styles from './RollerBlindSelector.module.scss';

/**
 * IRollerBlindSelector
 *
 * @description RollerBlindSelector component props
 * @property {Product} product Current product
 * @property {any} activeProductVariant Selected product variant
 * @property {React.Dispatch<any>} setActiveProductVariant Callback to set a new product variant
 */
interface IRollerBlindSelector {
  product: Product;
  activeProductVariant: any;
  setActiveProductVariant: React.Dispatch<any>;
}

/**
 * RollerBlindSelector
 *
 * @description Product selection component used to select all options on the product and add to cart
 */
const RollerBlindSelector: React.FC<IRollerBlindSelector> = ({ product, activeProductVariant, setActiveProductVariant }) => {
  const { products, addBlindProductToCheckout } = useContext(ShopContext);
  const [height, setHeight] = useState<string>('');
  const [width, setWidth] = useState<string>('');

  const [selectedChain, setSelectedChain] = useState<string | null>();
  const [selectedBottonRail, setSelectedBottomRail] = useState<string | null>();

  const [rollType, setRollType] = useState<string | null>();
  const [controlSide, setControlSide] = useState<string | null>();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<'selectMaterial' | 'rollConfiguration' | 'selectChain'>('selectMaterial');

  /**
   * CalculateAnimationHeight
   *
   * @description Method used to animate selection
   * @returns {string} Returns new translation value
   */
  const calculateAnimationHeight = (): string => {
    switch (currentStep) {
      case 'rollConfiguration':
        return `-${productSliderHeight}px`;
      case 'selectChain':
        return `-${productSliderHeight * 2}px`;
      case 'selectMaterial':
      default:
        return '0px';
    }
  };

  const { transform } = useSpring({
    transform: `translateY(${calculateAnimationHeight()})`,
    config: { mass: 5, tension: 500, friction: 80 }
  });

  /**
   * OnVariantChange
   *
   * @description Method run on new variant select
   * @param {string} newVariantId Selected variant ID
   * @returns {void} Variant update no return
   */
  const onVariantChange = (newVariantId: string): void => {
    const newVariant = product?.variants.find(v => v.id === newVariantId);
    setActiveProductVariant(newVariant);
  };

  const isValidDimensions = () => isNumeric(height) && isNumeric(width);

  const validateProductSelections = (): boolean => true;

  /**
   * UpdateStepMethod
   *
   * @description Change steps or add item to cart
   * @param {string} _id Selected variant ID
   * @param {string} _height Selected height
   * @param {string} _width Selected width
   * @returns {void} Update step no return
   */
  const updateStepMethod = (_id: string, _height: string, _width: string): void => {
    if (currentStep === 'selectMaterial') {
      setCurrentStep('rollConfiguration');
    }

    if (currentStep === 'rollConfiguration') {
      setCurrentStep('selectChain');
    }

    if (currentStep === 'selectChain' && selectedChain && selectedBottonRail) {
      if (!products) {
        return;
      }

      const productDetails: IAddToCartProductDetails = {
        height: _height,
        width: _width,
        rollType: rollType,
        bottomRailColour: selectedBottonRail,
        chainControlSide: controlSide
      };

      // Get selected chain material name
      const chainProducts = getAdditionalProduct(products, PRODUCT_TYPES.CHAIN);

      if (chainProducts) {
        const selectedChainProduct = chainProducts.variants.find(variant => variant.id === selectedChain);
        productDetails.chainMaterial = selectedChainProduct?.title;
      }

      // Get selected bottom rail mateiral name
      const bottomRailProducts = getAdditionalProduct(products, PRODUCT_TYPES.BOTTOM_RAIL);

      if (bottomRailProducts) {
        const selectedBottomRailProducts = bottomRailProducts.variants.find(variant => variant.id === selectedBottonRail);
        productDetails.bottomRailColour = selectedBottomRailProducts?.title;
      }

      addBlindProductToCheckout(_id, productDetails);
    }
  };

  /**
   * GenerateChainButtons
   *
   * @param {Array<Product> | null} _products List of all products
   * @param productType
   * @description Create the chain buttons
   */
  const getAdditionalProduct = (_products: Array<Product> | null, productType: string) => {
    if (!_products) {
      return null;
    }

    return _products.find(product => product.type === productType);
  };

  /**
   * CalculatePrice
   *
   * @description Calculate total product cost including additional products
   * @returns {string} Returns total price
   */
  const calculatePrice = (): string => `$${parseInt(height, 10) * parseInt(width, 10)}`;

  return (
    <>
      <Modal
        title='Select roller blind texture'
        className={styles.modal}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}>
        <div className={styles.variant__container}>
          {
            product.variants.map(({ id, title }) => (
              <VariantButton
                active={id === activeProductVariant?.id}
                key={id}
                onClick={() => onVariantChange(id)}
                imgSrc={getVariantImg(title) ?? ''}
              />
            ))
          }
        </div>
      </Modal>

      <div className={styles.product_content}>
        <div className={styles.form_selection}>
          <animated.div style={{ transform }} className={styles.form_selection__inner}>
            <div className={styles.form_selection__container}>
              <h1>{product.title}</h1>
              <p className={styles.product__description}>{product.description}</p>
              <DimensionsInput
                height={height}
                width={width}
                setHeight={setHeight}
                setWidth={setWidth}
              />
              <div className={styles.form_selection__variant_inner}>
                <div className={styles.variant__container}>
                  {
                    product.variants.map(({ id, title }, index) => {
                      if (index > 9) {
                        return null;
                      }

                      return (
                        <VariantButton
                          active={id === activeProductVariant?.id}
                          key={id} onClick={() => onVariantChange(id)}
                          imgSrc={getVariantImg(title) ?? ''}
                        />
                      );
                    })
                  }
                </div>
                <button
                  className={styles.form_selection__see_more}
                  onClick={() => setIsModalVisible(true)}>
                  See all
                </button>
                {
                  activeProductVariant?.title &&
                    <p className={styles.form_selection__selected_variant}>
                      Selected colour: <strong>{capitaliseWord(activeProductVariant.title)}</strong>
                    </p>
                }
              </div>
            </div>

            <RollTypeSelection
              railProduct={getAdditionalProduct(products, PRODUCT_TYPES.BOTTOM_RAIL)}
              selectedBottomRailId={selectedBottonRail}
              setSelectedChain={setSelectedBottomRail}
              setRollType={setRollType}
            />

            <ChainSelection
              chainProduct={getAdditionalProduct(products, PRODUCT_TYPES.CHAIN)}
              selectedChainId={selectedChain}
              setSelectedChain={setSelectedChain}
              setControlSide={setControlSide}
            />
          </animated.div>
        </div>
        <div className={styles.cart_button_container}>
          <Button
            className={styles.product__backButton}
            onClick={() => setCurrentStep('selectMaterial')}
          >
            Back
          </Button>
          <Button
            className={styles.product__addCartButton}
            onClick={() => updateStepMethod(activeProductVariant.id, height, width)}
          >
            {
              currentStep === 'selectChain' ? 'Add to cart' : 'Next'
            }
          </Button>
        </div>
        <h2>
          {
            isValidDimensions()
              ? calculatePrice()
              : `Starting price - $${activeProductVariant?.price}`
          }
        </h2>
      </div>
    </>
  );
};

export default RollerBlindSelector;

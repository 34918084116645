/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'antd';
import { ColSize } from 'antd/lib/grid';

import routes from 'routes';
import { Product } from 'types/productTypes';

import styles from './ProductGridItem.module.scss';

declare type ColSpanType = number | string;

interface Props {
  product: Product;
  guide?: boolean;
  span?: number;
  xs?: ColSpanType | ColSize;
  sm?: ColSpanType | ColSize;
  md?: ColSpanType | ColSize;
  lg?: ColSpanType | ColSize;
  xl?: ColSpanType | ColSize;
  xxl?: ColSpanType | ColSize;
}

const mapProductIdToGuidePage = (productId: string) => {
  switch (productId) {
    case 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY4Mjk3ODE5NzUyMTM=': // Roller blinds
      return 'roller-blinds';
    case 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY4MTcyMDA3OTk5MTc=': // Venetian blinds
      return 'venetian-blinds';
    case 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY5ODM0NTM4MDI2Njk=': // Plantation blinds
      return 'plantation-blinds';
    case 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY5ODM1MTk3OTc0MjE=': // Honey Comb blinds
      return 'honey-comb-blinds';
    case 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY5ODM1MjE1MDEzNTc=': // Double Roller blinds
      return 'double-roller-blinds';
    case 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY5ODM1MjQ2NDcwODU=': // Vertical blinds
      return 'vertical-blinds';
  }
};

const ProductGridItem: React.FC<Props> = ({
  product,
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  guide = false
}) => (
  <Col span={span} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
    <div className={styles.productGrid__item__container}>
      <Link to={guide ? `${routes.pages.guides.root.path}/${mapProductIdToGuidePage(product.id)}` : `${routes.pages.products.path}/${product.id}`}>
        <div className={`${styles.productImage__container} ${guide ? styles.guide : ''}`}>
          <img src={product.image.url} alt={product.image.alt} />
        </div>
        <h2>{product.title}</h2>
        {
          !guide &&
            <p className={styles.productGrid__item__price}>{`Starting $${product.price}`}</p>
        }
      </Link>
    </div>
  </Col>
);

export default ProductGridItem;

import React from 'react';

import { Header } from 'components';
import { Product } from 'types/productTypes';

import homePageDeco from 'assets/_temporary/home-page-deco-test-80.jpg';
import styles from './HomePageHeader.module.scss';

interface IHomePageHeaderProps {
  products: Array<Product>;
}

const HomePageHeader: React.FC<IHomePageHeaderProps> = () => (
  <div className={styles.homeHeader}>
    <Header />
    <div className={styles.homeHeader__content} style={{ backgroundImage: `url(${homePageDeco})` }}>
      <h1 className={styles.homeHeader__title}>Beautiful <span>blinds</span><br />made simple</h1>
    </div>
  </div>
);

export default HomePageHeader;

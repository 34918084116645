import React from 'react';

import Logo from 'components/Logo';
import Navigation from 'components/Navigation';

import './Header.module.scss';

const Header: React.FC = () => (
  <header>
    <Logo />
    <Navigation />
  </header>
);

export default Header;

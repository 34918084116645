/* eslint-disable react/prop-types */
import React, { ReactNode } from 'react';

import styles from './Container.module.scss';

interface Props {
  className?: string;
  width?: number;
  children: ReactNode;
}

const Container: React.FC<Props> = ({ className, width = 1100, children }) => (
  <div className={`${styles.container} ${className ?? ''}`} style={{ maxWidth: `${width}px` }}>
    {children}
  </div>
);

export default Container;

import { Button, Drawer } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import routes from 'routes';
import { ShopContext } from 'context/ShopContext';
import { marshalProductVariant } from 'common/shopifyUtil';

import CartItem from 'components/CartItem';

import styles from './CartDrawer.module.scss';

const CartDrawer: React.FC = () => {
  const { isCartOpen, closeCart, checkout } = useContext(ShopContext);
  const isMobile = useMediaQuery({ query: '(max-width: 764px)' });

  return (
    <Drawer
      className={styles.cartDrawer}
      width={isMobile ? 400 : 550}
      placement={'right'}
      onClose={closeCart}
      visible={isCartOpen}
      key={'cart-drawer'}
    >
      <h1 className={styles.title}>Your cart</h1>
      {/* <Link to={routes.pages.cart.path} onClick={closeCart}>

      </Link> */}
      <div className={styles.cartDrawer__productContainer}>
        {
          !checkout || checkout.lineItems.length < 1
            ? 'Cart is empty'
            : checkout?.lineItems.map((item: any) => {
              const productVariant = marshalProductVariant(item.variant);

              const height = item.customAttributes.find((item: any) => item.key === 'Height').value;
              const width = item.customAttributes.find((item: any) => item.key === 'Width').value;

              return (
                <CartItem
                  key={item.id}
                  id={item.id}
                  quantity={`${height} x ${width}`}
                  title={item.title}
                  variantTitle={productVariant.title}
                  image={productVariant.image}
                  price={productVariant.price}
                />
              );
            })
        }
      </div>
      {
        checkout && checkout.lineItems.length > 1 &&
        <p className={styles.cartDrawer__subtotal}>SubTotal - <strong>${checkout.subtotalPrice}</strong></p>
      }
      <div className={styles.cartDrawer__buttonContainer}>
        <Button
          className={styles.cartDrawer__keepBrowsing}
          onClick={closeCart}>
          Keep Browsing
        </Button>
        <Link
          className={styles.cartDrawer__confirmButton}
          to={routes.pages.cart.path}
          onClick={closeCart}>
          Checkout
        </Link>
      </div>
    </Drawer>
  );
};

export default CartDrawer;

import React from 'react';

import './GuideVideoEmbed.scss';

interface IGuideVideoEmbed {
  embedId: string;
}

const GuideVideoEmbed: React.FC<IGuideVideoEmbed> = ({ embedId }) => (
  <div className='video-responsive'>
    <iframe
      width='853'
      height='480'
      src={`https://www.youtube.com/embed/${embedId}?controls=1&modestbranding=1`}
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      title='Embedded youtube'
    />
  </div>
);

export default GuideVideoEmbed;
import React, { useContext, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { Popconfirm } from 'antd';

import { ProductImage } from 'types/productTypes';
import { ShopContext } from 'context/ShopContext';
import { capitaliseWord } from 'common/util';

import Button from 'components/Button';

import styles from './CartItem.module.scss';

interface ICartItem {
  id: string;
  title: string;
  variantTitle: string;
  image: ProductImage;
  price: string;
  quantity: string;
}

const CartItem: React.FC<ICartItem> = ({
  id, title, image, price, quantity, variantTitle
}) => {
  const { removeItemFromCheckout, error } = useContext(ShopContext);
  const [confirmLoading, setConfirmLoading] = useState<boolean>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const removeCartItem = async () => {
    setConfirmLoading(true);
    await removeItemFromCheckout(id);
    setConfirmLoading(false);
    setShowConfirm(false);

    if (error) {
      console.error('Unhandled error in removing item from cart: ', error);
    }
  };

  return (
    <div className={styles.cartItem__container}>
      <div className={styles.cartItem__imageContainer} style={{ backgroundImage: `url('${image.url}')` }} />
      {/* <img src={image.url} alt={image.alt} /> */}

      <h2 className={styles.cartItem__title}>{title}</h2>
      <p className={styles.cartItem__subTitle}>{capitaliseWord(variantTitle)}</p>

      <div className={styles.cartItem__priceContainer}>
        <p>{quantity}</p>
        <p>${price}</p>
      </div>

      <Popconfirm
        title={`Remove ${variantTitle} ${title} from cart?`}
        visible={showConfirm}
        onConfirm={removeCartItem}
        onCancel={() => setShowConfirm(false)}
        placement='rightTop'
        okButtonProps={{ loading: confirmLoading }}
      >
        <Button className={styles.cartItem__removeButton} onClick={() => setShowConfirm(true)}>
          <IoCloseCircleOutline size={24} />
        </Button>
      </Popconfirm>

    </div>
  );
};

export default CartItem;

/* eslint-disable react/prop-types */
import React, { ReactNode } from 'react';
import { Row } from 'antd';

import styles from './ProductGrid.module.scss';

interface Props {
  className?: string;
  title?: string;
  gutter: number;
  children: ReactNode;
}

const ProductGrid: React.FC<Props> = ({
  className, title, gutter, children
}) => (
  <div className={`${className ?? ''} ${styles.productsGrid__container}`}>
    {
      title && <h1 className={styles.productGrid__title}>{title}</h1>
    }
    <Row gutter={gutter}>
      {children}
    </Row>
  </div>
);

export default ProductGrid;

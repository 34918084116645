import React from 'react';
import { Link } from 'react-router-dom';

import routes from 'routes';

import logo from 'assets/_temporary/logo_temp.png';
import styles from './Logo.module.scss';

const Logo: React.FC = () => (
  <div className={styles.logoContainer}>
    <Link to={routes.pages.home.path}>
      <img src={logo} className={styles.logo} alt='logo' />
    </Link>
  </div>
);

export default Logo;

/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Badge } from 'antd';
import { NavLink } from 'react-router-dom';
import { IoPricetagOutline } from 'react-icons/io5';

import routes from 'routes';

import { Button } from 'components';
import { ShopContext } from 'context/ShopContext';

import styles from './Navigation.module.scss';

interface Props {
  hideCartButton?: boolean;
}

const Navigation: React.FC<Props> = ({ hideCartButton }) => {
  const { toggleCart, checkout } = useContext(ShopContext);
  const lineItemCount: number = checkout?.lineItems.length ?? 0;

  return (
    <nav className={hideCartButton ? styles.cartButton__hidden : ''}>
      <ul className={styles.menu}>
        <li><NavLink to={routes.pages.guides.root.path} activeClassName={styles.navbar__link__active}>Guides</NavLink></li>
        <li><NavLink to={routes.pages.products.path} activeClassName={styles.navbar__link__active}>Shop</NavLink></li>
      </ul>
      {
        !hideCartButton
          && (
            <div className={styles.cartButton}>
              <Button onClick={toggleCart}>
                <Badge
                  size='default'
                  count={lineItemCount}
                  title={`${lineItemCount} items in your cart`}
                  offset={[-10, 22]}
                >
                  <IoPricetagOutline size={30} />
                </Badge>
              </Button>
            </div>
          )
      }
    </nav>
  );
};

export default Navigation;

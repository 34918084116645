import React, { useEffect, useContext } from 'react';
import { Row } from 'antd';
import { Link } from 'react-router-dom';

import { Product } from 'types/productTypes';
import { ShopContext } from 'context/ShopContext';
import routes from 'routes';

import {
  HomePageHeader,
  Section,
  Footer,
  StepSlider,
  ProductGridItem
} from 'components';

import homePageDeco from 'assets/_temporary/home-page-deco-test-80.jpg';
import styles from './HomePage.module.scss';

const HomePage: React.FC = () => {
  const { fetchAllProducts, products } = useContext(ShopContext);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  // TODO: Add filter for featured products
  const generateProductsGrid = (_products: Array<Product>) => (
    _products
      .slice(0, 3)
      .map((product) => (
        <ProductGridItem
          key={product.id}
          product={product}
          xs={24}
          sm={24}
          md={8}
          xxl={6}
        />
      ))
  );

  return (
    <>
      <HomePageHeader products={products?.slice(0, 4) ?? []} />

      <Section className={styles.featureProducts}>
        <h1>Our favourites</h1>
        <p className={styles.featureProducts__blurb}>Pick from some of our favourite blinds, or see our <Link to={routes.pages.products.path}>full range</Link> and find something you love</p>
        <Row gutter={0}>
          {
            (products && products.length > 0) && generateProductsGrid(products)
          }
        </Row>
      </Section>

      <Section>
        <StepSlider />
      </Section>

      {/* <Section>
        <Container className={styles.callToAction}>
          <h1>Still need help?</h1>
          <ul>
            <li>
              <Button onClick={() => routes.pages.products.push(history)}>
                No I am ready to shop
              </Button>
            </li>
            <li>
              <Button onClick={() => console.log('linkTwo')}>
                Yes get in touch
              </Button>
            </li>
          </ul>
        </Container>
      </Section> */}

      <div className={styles.homePageDecoContainer}>
        <div
          className={styles.homePageDecoImage}
          style={{ backgroundImage: `url(${homePageDeco})` }}
        />
      </div>

      <Footer />
    </>
  );
};

export default HomePage;

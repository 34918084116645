import React, { useEffect, useState } from 'react';
import Client, { Cart } from 'shopify-buy';

import { Product } from 'types/productTypes';
import { IAddToCartProductDetails } from './shopContextTypes';
import { NO_SHOPIFY_DOMAIN, NO_STORE_FRONT_API_KEY } from 'common/constants';
import { marshalProduct, marshalProducts } from 'common/shopifyUtil';

const client = Client.buildClient({
  storefrontAccessToken: process.env.REACT_APP_STORE_FRONT_API_KEY ?? NO_STORE_FRONT_API_KEY,
  domain: process.env.REACT_APP_SHOPIFY_DOMAIN ?? NO_SHOPIFY_DOMAIN
});

interface ShopContextType {
  products: Product[] | null;
  product: Product | null;
  checkout: Cart | undefined;
  isCartOpen: boolean | undefined;
  error: string | null;
  unsetProduct: () => void;
  fetchAllProducts: () => void;
  fetchProductWithId: (id: string) => Promise<Product | null>;
  closeCart: () => void;
  openCart: () => void;
  toggleCart: () => void;
  addBlindProductToCheckout: (variantId: string, productDetails: IAddToCartProductDetails) => void;
  removeItemFromCheckout: (lineitemId: string) => void;
  updateCheckoutNote: (note: string) => void;
}

const ShopContext = React.createContext<ShopContextType>({} as ShopContextType);

const ShopProvider: React.FC = ({ children }) => {
  const [products, setProducts] = useState<Product[] | null>(null);
  const [product, setProduct] = useState<Product | null>(null);
  const [checkout, setCheckout] = useState<Cart>();
  const [isCartOpen, setIsCartOpen] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);

  const closeCart = () => {
    setIsCartOpen(false);
  };

  const openCart = () => {
    setIsCartOpen(true);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const resetError = () => {
    if (error) {
      setError(null);
    }
  };

  const createCheckout = async () => {
    resetError();

    try {
      const newCheckout = await client.checkout.create();

      if (!newCheckout) {
        console.error('Failed to create checkout');
        // Display error to user
        return;
      }
      localStorage.setItem('checkout', newCheckout.id.toString());
      setCheckout(newCheckout);
    }
    catch (err: any) {
      setError(err);
    }
  };

  const fetchCheckout = async (checkoutId: string) => {
    resetError();

    try {
      const newCheckout = await client.checkout.fetch(checkoutId);
      setCheckout(newCheckout);
    }
    catch (err: any) {
      console.error(`Failed to fetch checkout with id: ${checkoutId}`);
      console.error(err);

      setError(err);
    }
  };

  const addProductToCheckout = async (lineItems: Client.LineItemToAdd[]) => {
    resetError();

    if (!checkout) {
      const err = 'Cart does not exist';

      console.error(err);

      setError(err);

      return;
    }

    try {
      const newCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItems
      );
      setCheckout(newCheckout);

      openCart();
    }
    catch (err: any) {
      console.error('Failed to add item to cart: ', lineItems);
      console.error(err);

      setError(err);
    }
  };

  // Const addBasicProduct = async (variantId: string) => {
  //   Const lineItemsToAdd = [
  //     {
  //       VariantId,
  //       Quantity: 1
  //     }
  //   ];

  //   AddProductToCheckout(lineItemsToAdd);
  // };

  const addBlindProductToCheckout = async (variantId: string, productDetails: IAddToCartProductDetails) => {
    resetError();

    if (!checkout) {
      const err = 'Cart does not exist';

      console.error(err);

      setError(err);

      return;
    }

    const area = parseInt(productDetails.height, 10) * parseInt(productDetails.width, 10);
    const lineItemsToAdd = [
      {
        variantId,
        quantity: area,
        customAttributes: [
          {
            key: 'Height',
            value: `${productDetails.height}mm`
          },
          {
            key: 'Width',
            value: `${productDetails.width}mm`
          },
          {
            key: 'Area',
            value: `${area}mm`
          }
        ]
      }
    ];

    // TODO: Maybe add a helper for this
    if (productDetails.chainControlSide) {
      lineItemsToAdd[0].customAttributes.push({ key: 'Chain control side', value: productDetails.chainControlSide });
    }

    if (productDetails.chainMaterial) {
      lineItemsToAdd[0].customAttributes.push({ key: 'Chain material', value: productDetails.chainMaterial });
    }

    if (productDetails.rollType) {
      lineItemsToAdd[0].customAttributes.push({ key: 'Roll type', value: productDetails.rollType });
    }

    if (productDetails.bottomRailColour) {
      lineItemsToAdd[0].customAttributes.push({ key: 'Bottom rail colour', value: productDetails.bottomRailColour });
    }

    addProductToCheckout(lineItemsToAdd);
  };

  const removeItemFromCheckout = async (lineItemId: string) => {
    resetError();

    if (!checkout) {
      const err = 'Cart does not exist';

      console.error(err);

      setError(err);

      return;
    }

    const lineItemsToRemove = [lineItemId];

    try {
      await client.checkout.removeLineItems(
        checkout.id.toString(),
        lineItemsToRemove
      );

      // Fetch and set updated checkout
      fetchCheckout(checkout.id.toString());
    }
    catch (err: any) {
      console.error(`Failed to remove item with id '${lineItemId}' from cart`);
      console.error(err);

      setError(err);
    }
  };

  const fetchAllProducts = async () => {
    resetError();
    try {
      const fetchedProducts = await client.product.fetchAll();
      const marshaledProducts = marshalProducts(fetchedProducts);
      setProducts(marshaledProducts);
    }
    catch (err: any) {
      console.error('Failed to fetch all products');
      console.error(err);

      setError(err);
    }
  };

  const fetchProductWithId = async (id: string): Promise<Product | null> => {
    resetError();

    try {
      const fetchedProduct = await client.product.fetch(id);
      const marshaledProduct = marshalProduct(fetchedProduct);
      setProduct(marshaledProduct);

      return marshaledProduct;
    }
    catch (err: any) {
      console.error(`Failed to fetch product with id: ${id}`);
      console.error(err);

      setError(err);

      return null;
    }
  };

  const unsetProduct = () => {
    setProduct(null);
  };

  useEffect(() => {
    // Check if localStorage has a checkout_id saved
    if (localStorage.checkout) {
      fetchCheckout(localStorage.checkout);
    }
    else {
      createCheckout();
    }
    // If there is no checkout_id in localStorage then we will create a new checkout
    // Else fetch the checkout from store front api
  }, []);

  const updateCheckoutNote = async (note: string) => {
    resetError();

    if (!checkout) {
      const err = 'Cart does not exist';

      console.error(err);

      setError(err);

      return;
    }

    const input = { customAttributes: [{ key: 'cartnote', value: note }] };

    try {
      (client.checkout as any).updateAttributes(checkout.id, input);
    }
    catch (err: any) {
      console.error('Failed to add note to cart');
      console.error(err);

      setError(err);
    }
  };

  return (
    <ShopContext.Provider
      value={{
        products,
        product,
        checkout,
        isCartOpen,
        error,
        fetchAllProducts,
        fetchProductWithId,
        unsetProduct,
        closeCart,
        openCart,
        toggleCart,
        addBlindProductToCheckout,
        removeItemFromCheckout,
        updateCheckoutNote
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

const ShopConsumer = ShopContext.Consumer;

export { ShopConsumer, ShopContext };

export default ShopProvider;

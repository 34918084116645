import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import {
  Row, Col
} from 'antd';

import { Product } from 'types/productTypes';
import { ShopContext } from 'context/ShopContext';

import {
  Header,
  Section,
  ProductGridItem,
  ProductGrid,
  Footer,
  Container,
  ProductSelection
} from 'components';
import {
  RollerBlindSelector
} from 'components/_ProductComponents/_ProductSelectionViews';
import { getRelatedProducts } from './ProductUtils';

import styles from './ProductPage.module.scss';

const generateRelatedProductsGrid = (
  products: Array<Product>,
  currentProduct: Product
) => {
  const similarProducts = getRelatedProducts(products, currentProduct);

  return similarProducts.map(
    product => (
      <ProductGridItem
        key={product.id}
        product={product}
        xs={24}
        sm={24}
        md={8}
      />
    )
  );
};

const ProductPage: React.FC = () => {
  const { productId } = useParams<{ productId: string; }>();
  const {
    fetchAllProducts, fetchProductWithId,
    product, unsetProduct, products
  } = useContext(ShopContext);
  const [activeProductVariant, setActiveProductVariant] = useState<any>();

  const isMobile = useMediaQuery({ query: '(max-width: 764px)' });

  useEffect(() => {
    fetchAllProducts();
    fetchProductWithId(productId);
    return () => {
      unsetProduct();
    };
  }, []);

  useEffect(() => {
    setActiveProductVariant(product?.variants[0]);
  }, [product]);

  return (
    <>
      <Header />
      <Section className={styles.product}>
        <Row className={styles.product__container}>
          <Col className={styles.product__details_container}>
            {
              product &&
                <RollerBlindSelector
                  product={product}
                  activeProductVariant={activeProductVariant}
                  setActiveProductVariant={setActiveProductVariant}
                />
            }
          </Col>
          <Col className={styles.product__image__container}>
            {
              product &&
                (
                  activeProductVariant
                    ? <img src={activeProductVariant.image.url} alt={activeProductVariant.image.alt} />
                    : <img src={product.image.url} alt={product.image.alt} />
                )
            }
          </Col>
        </Row>

        <Container className={styles.similarStyle__container}>
          <ProductGrid title='Similar styles' gutter={isMobile ? 0 : 32}>
            {
              products && product &&
                generateRelatedProductsGrid(products, product)
            }
          </ProductGrid>
        </Container>
      </Section>

      <Footer />
    </>
  );
};

export default ProductPage;
